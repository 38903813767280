import React from 'react';
import { Box } from '@mui/material';
import { ArticleProvider } from '../contexts/ArticleContext';
import Header from '../components/Header';
import ArticleFormatter from '../components/ArticleFormatter';
import { ErrorProvider } from '../contexts/ErrorContext';

const MainPage = () => {
  return (
    <ArticleProvider>
    <ErrorProvider>
      <Box>
        <Header />
        <ArticleFormatter />
      </Box>
    </ErrorProvider>
    </ArticleProvider>
  );
}

export default MainPage;
