import React, { useEffect, useMemo } from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';
import { Chapter, SentenceContent } from '../model/Chapter';
import { useErrorContext } from '../contexts/ErrorContext';

const countContentElements = (chapters: Chapter[]) => {
  let imageCount = 0;
  let tableCount = 0;
  let referenceCount = 0;

  const traverseChapters = (chapters: Chapter[]) => {
    chapters.forEach((chapter) => {
      chapter.contents.forEach((content) => {
        if (content.type === 'image') {
          imageCount++;
        } else if (content.type === 'table') {
          tableCount++;
        } else if (content.type === 'sentence') {
          const sentenceContent = content as SentenceContent;
          if (sentenceContent.bibtex && sentenceContent.bibtex.length > 0) {
            referenceCount++;
          }
        }
      });

      if (chapter.subChapters && chapter.subChapters.length > 0) {
        traverseChapters(chapter.subChapters);
      }
    });
  };

  traverseChapters(chapters);
  return { imageCount, tableCount, referenceCount };
};

const ArticleDetails = () => {
  const { article } = useArticleContext();
  const { setError } = useErrorContext();

  useEffect(() => {
    if (article) {
      if (!article.title ||
        !article.authors ||
        !article.abstract ||
        !article.keywords ||
        article.keywords.length === 0 ||
        !article.chapters) {
          setError(null, {errorWithInstructions: true});
        }
    }
  }, [article]);

  if (!article) return null;

  return (
    <Box mt={2}>
      <Typography variant="body1">
        Detected elementes in file:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Title"
            secondary={
              article.title ? (
                article.title
              ) : (
                <Typography component="span" style={{ color: 'red' }}>
                  No Title detected, please apply Title Style in Word(check INSTRUCTIONS above).
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Authors"
            secondary={
              article.authors ? (
                article.authors
              ) : (
                <Typography component="span" style={{ color: 'red' }}>
                  No Authors detected, please point to the Authors list in Word using "Authors: " prefix.
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Abstract"
            secondary={
              article.abstract ? (
                article.abstract
              ) : (
                <Typography component="span" style={{ color: 'red' }}>
                  No Abstract detected, please point to the Abstract in Word using "Abstract: " prefix.
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Keywords"
            secondary={
              article.keywords && article.keywords.length > 0 ? (
                article.keywords.join(', ')
              ) : (
                <Typography component="span" style={{ color: 'red' }}>
                  No Keywords detected, please point to the Keywords list in Word using "Keywords: " prefix.
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Chapters"
            secondary={
              article.chapters ? (
                `${article.chapters.length} chapters`
              ) : (
                <Typography component="span" style={{ color: 'red' }}>
                  No Chapters detected, please apply Heading 1 Style in Word(check INSTRUCTIONS above).
                </Typography>
              )
            }
          />
        </ListItem>
        {article.chapters && (() => {
          const { imageCount, tableCount, referenceCount } = countContentElements(article.chapters);
          return (
            <>
              <ListItem>
                <ListItemText primary="Images" secondary={`${imageCount} images`} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Tables" secondary={`${tableCount} tables`} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="References"
                  secondary={
                    referenceCount > 0 ? (
                      `${referenceCount} references`
                    ) : (
                      <Typography component="span" style={{ color: 'red' }}>
                        No References detected, please use Mendeley, Zotero or OneNote to label References.
                      </Typography>
                    )
                  }
                  />
              </ListItem>
            </>
          );
        })()}
      </List>
    </Box>
  );
};

export default ArticleDetails;