import React from 'react';
import { uploadDocument } from '../api/endpoints/generateDocumentOrPreview';
import { Button } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';
import { saveAs } from 'file-saver';
import {useMixpanel} from '../contexts/MixpanelContext';

const DownloadArticleButton = () => {
  const mixpanel = useMixpanel();
  const {
    articleID,
    article,
  } = useArticleContext();

  const handleDownloadDocument = async () => {
    if (!articleID || !article) {
      console.error('ArticleID or article is missing:', { articleID, article });
      return;
    }

    const eventName = "fe_download_document";
    mixpanel.startTimeMeasurement(eventName);

    try {
      const pdfBlob = await uploadDocument(articleID);
      if (pdfBlob) {
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        saveAs(pdfBlob, `${article?.title}.pdf`);
        window.URL.revokeObjectURL(pdfUrl);
      }
      mixpanel.trackEvent(eventName,{success: true});
    } catch (error) {
      mixpanel.trackEvent(eventName,{success: false});
      console.error('Failed to format document:', error);
      console.log('An error occurred while generating the article. Please try again.');
    }
  };

  return (
    <Button
      onClick={handleDownloadDocument}
      variant="contained"
      color="success"
      sx={{ minWidth: 150, textTransform: 'none', backgroundColor: '#5BD7D6' }}
    >
      Download Document
    </Button>
  );
};

export default DownloadArticleButton;