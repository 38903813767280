import React, { useEffect, useState } from 'react';
import { getPublishers } from '../api/endpoints/PublishersJournalsBibliography';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';

const PublishersDropDown = () => {
	const {
    publisher,
    setPublisher,
  } = useArticleContext();
	const [publishers, setPublishers] = useState<string[]>([]);

	useEffect(() => {
		const fetchPublishers = async () => {
			try {
				const data = await getPublishers();
				setPublishers(data);
			} catch (error) {
				console.error("Error fetching publishers: ", error);
			}
		};

		fetchPublishers();
	}, []);

	const handleSelectChange = (event: SelectChangeEvent<string>) => {
		const selectedValue = event.target.value;
		setPublisher(selectedValue);
	};

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<FormControl variant="outlined" size="small">
				<InputLabel id="publisher-dropdown-label">Select Publisher</InputLabel>
				<Select
					labelId="publisher-dropdown-label"
					id="publisher-dropdown"
					value={publisher}
					onChange={handleSelectChange}
					label="Select Publisher"
				>
					<MenuItem value="generic">
						Generic article format with adjustable Reference format
					</MenuItem>
					{publishers
						.filter((pub) => pub !== 'Generic')
						.map((publisher, index) => (
							<MenuItem key={index} value={publisher}>
								{publisher}
							</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default PublishersDropDown;
