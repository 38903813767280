import React, {useState} from "react";
import {getAuth, createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {firebaseConfig} from "../config/firebaseConfig";
import {Box, Button, Container, TextField, Typography, Link} from "@mui/material";
import {useMixpanel} from "../contexts/MixpanelContext";

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const mixpanel = useMixpanel();
    const eventName = "fe_register";

    const handleRegister = async (e: React.FormEvent) => {
        mixpanel.startTimeMeasurement(eventName);
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);
            mixpanel.trackEvent(eventName, {success: true});
            navigate('/verify-email');
        } catch (err: any) {
            mixpanel.trackEvent(eventName, {success: false});
            setError(err.message);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                <Typography variant="h4" gutterBottom>
                    Register
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <form onSubmit={handleRegister}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Register
                    </Button>
                </form>
                <Box mt={2}>
                    <Link component={RouterLink} to="/login">
                        Go to Login
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

export default Register;