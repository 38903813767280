import React, { useEffect, useState } from 'react';
import { getBibliography, getJournalsByPublisher } from '../api/endpoints/PublishersJournalsBibliography';
import { Autocomplete, Box, TextField, CircularProgress, Typography } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';

const JournalOrBibliographyDropDown = () => {
	const {
    publisher,
    setJournal,
    setBibliography,
  } = useArticleContext();
	const [allOptions, setAllOptions] = useState<string[]>([]);
  const [displayedOptions, setDisplayedOptions] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [selectedValue, setSelectedValue] = useState<string | null>(null);

	const CHUNK_SIZE = 50;

	useEffect(() => {
		const fetchOptions = async () => {
			setLoading(true);
			try {
				let options: string[] = [];
				if (publisher.toLowerCase() === 'generic') {
					const bibliographyStyles = await getBibliography();
					options = Object.values(bibliographyStyles).flat();
					setBibliography('');
				} else {
					options = await getJournalsByPublisher(publisher);
					setJournal('');
				}
				setAllOptions(options);
        setDisplayedOptions(options.slice(0, CHUNK_SIZE));
			} catch (error) {
				console.error(`Failed to fetch options for publisher '${publisher}':`, error);
			} finally {
        setLoading(false);
      }
		};

		if (publisher) {
			setAllOptions([]);
      setDisplayedOptions([]);
			setSelectedValue(null);
			setBibliography('');
			setJournal('');
      fetchOptions();
		}
	}, [publisher, setJournal, setBibliography]);

	useEffect(() => {
		const filteredOptions = allOptions.filter((option) =>
			option.toLowerCase().includes(searchQuery.toLowerCase())
		);
		setDisplayedOptions(filteredOptions.slice(0, CHUNK_SIZE));
	}, [searchQuery, allOptions]);

	const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const listNode = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = listNode;

    if (scrollHeight - scrollTop <= clientHeight + 50 && displayedOptions.length < allOptions.length) {
      const nextChunk = allOptions
				.filter((option) => option.toLowerCase().includes(searchQuery.toLowerCase()))
				.slice(displayedOptions.length,displayedOptions.length + CHUNK_SIZE);
      setDisplayedOptions((prevOptions) => [...prevOptions, ...nextChunk]);
    }
  };

	const handleOptionChange = (event: React.SyntheticEvent, value: string | null) => {
		setSelectedValue(value);

    if (!value) {
			if (publisher.toLowerCase() === 'generic') {
				setBibliography('');
			} else {
				setJournal('');
			}
			return;
		}

    if (publisher.toLowerCase() === 'generic') {
      setBibliography(value);
    } else {
      setJournal(value);
    }
  };

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<Autocomplete
				size="small"
				id="journal-or-bibliography-dropdown"
				options={displayedOptions}
				loading={loading}
				inputValue={searchQuery}
				value={selectedValue}
				onInputChange={(event, newValue) => setSearchQuery(newValue)}
				onChange={handleOptionChange}
				filterOptions={(options) => options}
				getOptionLabel={(option) => option}
				renderInput={(params) => (
          <TextField
            {...params}
            label={publisher.toLowerCase() === 'generic' ? 'Search Reference Format' : 'Search Journal'}
            placeholder="Type to search or scroll"
            variant="outlined"
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
          style: { maxHeight: 300, overflow: 'auto' },
        }}
        renderOption={(props, option, index) => (
          <Box component="li" {...props} key={`${option}-${index}`}>
            {option}
          </Box>
        )}
        noOptionsText={
          !loading ? (
            <Typography>No options available</Typography>
          ) : (
            <CircularProgress size={20} />
          )
        }
			/>
		</Box>
	);
};

export default JournalOrBibliographyDropDown;