import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Paper, Alert, AlertTitle, CircularProgress, IconButton } from '@mui/material';
import { useConvertDocxToArticle } from '../api/endpoints/useConvertDocxToArticle';
import { useArticleContext } from '../contexts/ArticleContext';
import { useErrorContext } from '../contexts/ErrorContext';
import CloseIcon from '@mui/icons-material/Close';
import {useMixpanel} from "../contexts/MixpanelContext";
// import Editor from '../editor/Editor';
// import Mammoth from 'mammoth';

const DocxReader = () => {
  const [file, setFile] = useState<File | null>(null);
  const { article, setArticle, setArticleID } = useArticleContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const { setError } = useErrorContext();
  const convertDocxToArticle = useConvertDocxToArticle();
  const mixpanel = useMixpanel();
  // const [editorContent, setEditorContent] = useState("{}");

  useEffect(() => {
    if (!file) return;
    
    const parseDocx = async () => {
        const eventName = "fe_parse_article";
        mixpanel.startTimeMeasurement(eventName);
        setLoading(true);
        try {
            const parsedArticle = await convertDocxToArticle(file);
            setArticle(parsedArticle);
            mixpanel.trackEvent(eventName, {success: true});
        } catch (error) {
            mixpanel.trackEvent(eventName, {success: false});
            if (typeof error === 'string') {
                setError(error);
            } else if (error && typeof error === 'object' && 'message' in error) {
                setError((error as { message: string }).message);
            } else {
                setError(null, { errorWithInstructions: true });
            }
        } finally {
            setLoading(false);
        }
    };
      
    parseDocx();
  }, [file]);

  const handleFileValidation = (uploadedFile: File) => {
    if (uploadedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setError('The uploaded file is not a valid DOCX file. Please upload a file with the *.docx extension.');
      return false;
    }
    return true;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile && handleFileValidation(uploadedFile)) {
      setFile(uploadedFile);
    }
  };

  // const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const uploadedFile = event.target.files?.[0];
  //   if (uploadedFile && handleFileValidation(uploadedFile)) {
  //     setFile(uploadedFile);

  //     try {
  //       const arrayBuffer = await uploadedFile.arrayBuffer();
  //       const result = await Mammoth.convertToHtml({ arrayBuffer });
  //       const plainText = result.value;

  //       const initialDoc = {
  //         type: "doc",
  //         content: plainText.split("\n").map((line) => ({
  //           type: "paragraph",
  //           content: [{ type: "text", text: line.trim() }],
  //         })),
  //       };
  //       setEditorContent(JSON.stringify(initialDoc));
  //     } catch (error) {
  //       console.error('Error processing content to editor:', error);
  //     }
  //   }
  // };

  const handleDrag = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const droppedFile = event.dataTransfer.files[0];
      if (handleFileValidation(droppedFile)) {
        setFile(droppedFile);
      }
    }
  }, []);

  const handleClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setArticle(null);
    setArticleID('');
    // setEditorContent("{}");
  };

  return (
    <Box
      sx={{
        minWidth: 500,
        margin: '0 auto',
        padding: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <>
          <Typography variant="h6" component="h2" gutterBottom>
            We're interpreting your file
          </Typography>
          <CircularProgress />
        </>
      ) : (
        <>
          {!file ? (
            <>
            <Paper
              id="drag-drop-zone"
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
              onClick={handleClick}
              sx={{
                border: dragActive ? '2px dashed #0066cc' : '2px dashed #999',
                borderRadius: 2,
                padding: 4,
                textAlign: 'center',
                marginBottom: 2,
                cursor: 'pointer',
              }}
            >
              <Typography>
                {dragActive ? 'Drop the file here...' : 'Drag & Drop a DOCX file here, or click to upload'}
              </Typography>

              <input
                id="fileInput"
                type="file"
                accept=".docx"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </Paper>
          </>
          ) : (
            <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%', gap: 1 }}>
              <Typography variant="body1">
                Selected File: <strong>{file.name}</strong>
              </Typography>
              <IconButton
                aria-label="Remove file"
                onClick={handleRemoveFile}
                sx={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}

          {/* {file && (
            <Box mt={4} sx={{ width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Edit Your Content
              </Typography>
              <Editor initialContent={editorContent} onContentChange={setEditorContent} />
            </Box>
          )} */}
        </>
      )}
    </Box>
  );
};

export default DocxReader;