import React, { useState, useEffect } from 'react';
import { formatDocument } from '../api/endpoints/generateDocumentOrPreview';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';
import { useErrorContext } from '../contexts/ErrorContext';
import Preview from './Preview';
import {useMixpanel} from "../contexts/MixpanelContext";

const PrepareArticle = () => {
	const {
		articleID,
		setArticleID,
		totalPages,
		setTotalPages,
		zipFile,
		publisher,
		journal,
		bibliography,
		article,
	} = useArticleContext();
	const [loading, setLoading] = useState(false);
	const { setError } = useErrorContext();
	const mixpanel = useMixpanel();

	useEffect(() => {
    setArticleID('');
  }, [publisher, journal, bibliography]);

	const validateInputs = (): boolean => {
    if (!publisher || (!journal && !bibliography) || !article) {
      setError(
        'Please ensure that the Publisher, Journal, or Reference Format are properly selected, and verify that your document has been uploaded correctly.'
      );
      return false;
    }
    return true;
  };

	const handleGenerateDocument = async () => {
		if (!validateInputs()) return;
        
		const eventName = "fe_format_document";
		mixpanel.startTimeMeasurement(eventName);

		setLoading(true);
		setArticleID('');

		if (!zipFile) {
			setError(null, { errorWithInstructions: true });
			setLoading(false);
			return;
		}

		try {
			const {id, totalPages } = await formatDocument(zipFile, publisher, journal, bibliography);
			setArticleID(id);
			setTotalPages(totalPages);
			mixpanel.trackEvent(eventName,{success: true});
		} catch (error) {
			mixpanel.trackEvent(eventName,{success: false});
			console.error('Failed to format document:', error);
			setError(null, { errorWithInstructions: true });
			return;
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
			{!articleID ? (
				<Button
					onClick={handleGenerateDocument}
					variant="contained"
					color="primary"
					disabled={loading}
					startIcon={loading && <CircularProgress size={20} color="inherit" />}
					sx={{ minWidth: 150, textTransform: 'none', backgroundColor: '#5BD7D6' }}
				>
					{loading ? 'Formatting Article...' : 'Format Article'}
				</Button>
			) : (
				<>
					<Preview id={articleID} pages={totalPages}/>
				</>
			)}
			{loading && (
				<Typography variant="body2" color="textSecondary">
					Please wait while the Document is being generated.
				</Typography>
			)}
		</Box>
	);
};

export default PrepareArticle;