import React, {useState} from "react";
import {getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {useNavigate, Link as RouterLink} from "react-router-dom";
import {firebaseConfig} from "../config/firebaseConfig";
import {Box, Button, Container, TextField, Typography, Link} from "@mui/material";
import {useMixpanel} from "../contexts/MixpanelContext";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const googleProvider = new GoogleAuthProvider();
    const mixpanel = useMixpanel();
    const eventName = "fe_login";

    const handleLogin = async (e: React.FormEvent) => {
        mixpanel.startTimeMeasurement(eventName);
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            mixpanel.trackEvent(eventName, {success: true, method: "email"});
            navigate("/"); // Redirect to the main page after successful login
        } catch (error: any) {
            mixpanel.trackEvent(eventName, {success: false, method: "email"});
            setError(error.message);
        }
    };

    const handleGoogleLogin = async () => {
        mixpanel.startTimeMeasurement(eventName);
        try {
            await signInWithPopup(auth, googleProvider);
            mixpanel.trackEvent(eventName, {success: true, method: "google"});
            navigate("/"); // Redirect to the main page after successful login
        } catch (error: any) {
            mixpanel.trackEvent(eventName, {success: false, method: "google"});
            setError(error.message);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                <Typography variant="h4" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleLogin}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Login
                    </Button>
                </form>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleGoogleLogin}
                    sx={{mt: 2}}
                >
                    Login with Google
                </Button>
                <Box mt={2}>
                    <Link component={RouterLink} to="/forgot-password">
                        Forgot Password?
                    </Link>
                </Box>
                <Box mt={2}>
                    <Link component={RouterLink} to="/register">
                        Don't have an account? Register
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;