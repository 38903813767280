import React from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Header = () => {
	const navigate = useNavigate();
	const auth = getAuth();

	const handleLogout = async () => {
		try {
			await signOut(auth);
			navigate('/login'); // Redirect to login page after logout
		} catch (error) {
			console.error('Error logging out:', error);
		}
	};

	return (
		<AppBar position="static" color="transparent">
			<Toolbar>
				<Box display="flex" alignItems="center" flexGrow={1}>
					<img
						src="/logo.svg"
						alt="DrPaper Logo"
						style={{ width: 40, height: 40, marginRight: 10 }}
					/>
					<Typography variant="h6" component="div">
						DrPaper
					</Typography>
				</Box>
				<Button color="inherit" onClick={handleLogout}>
					Logout
				</Button>
			</Toolbar>
		</AppBar>
	);
};

export default Header;