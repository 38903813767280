import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface InstructionProps {
  open: boolean;
  onClose: () => void;
}

const Instruction: React.FC<InstructionProps> = ({ open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        Instructions
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center' }}>
          <img
            src="/instruction-image.png"
            alt="Instruction"
            style={{
              maxWidth: '100%',
              borderRadius: '8px',
              boxShadow: '3px 3px 10px rgba(0,0,0,0.2)',
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Instruction;
