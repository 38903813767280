// Import dependencies
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import mixpanel from 'mixpanel-browser';

type MixpanelContextType = {
    startTimeMeasurement: (eventName: string) => void;
    trackEvent: (eventName: string, properties?: Record<string, any>) => void;
    identifyUser: (userId: string) => void;
    setUserProfile: (profileData: Record<string, any>) => void;
} | null;

// Create Mixpanel Context
const MixpanelContext = createContext<MixpanelContextType>(null);

type MixpanelProviderProps = {
    children: ReactNode;
    config?: Record<string, any>;
};

// Mixpanel Provider Component
export const MixpanelProvider: React.FC<MixpanelProviderProps> = ({ children, config = { track_pageview: true} }) => {
    const [mixpanelInstance, setMixpanelInstance] = useState<typeof mixpanel | null>(null);

    useEffect(() => {
        if(!mixpanelInstance) {
        const token = process.env.REACT_APP_TELEMETRY_ID?.trim() || '';

        if (!token || token === '') {
            console.error('Mixpanel token is required to initialize.');
            return;
        }

            mixpanel.init(token, config);
            setMixpanelInstance(mixpanel);
        }
    }, [config]);

    const startTimeMeasurement = (eventName: string) => {
        if (mixpanelInstance) {
            mixpanelInstance.time_event(eventName);
        } else {
            console.error('Mixpanel is not initialized. Event time measurement not started:', eventName);
        }
    };

    const trackEvent = (eventName: string, properties?: Record<string, any>) => {
        if (mixpanelInstance) {
            mixpanelInstance.track(eventName, properties);
        } else {
            console.error('Mixpanel is not initialized. Event not tracked:', eventName);
        }
    };

    const identifyUser = (userId: string) => {
        if (mixpanelInstance) {
            mixpanelInstance.identify(userId);
        } else {
            console.error('Mixpanel is not initialized. User not identified:', userId);
        }
    };

    const setUserProfile = (profileData: Record<string, any>) => {
        if (mixpanelInstance) {
            mixpanelInstance.people.set(profileData);
        } else {
            console.error('Mixpanel is not initialized. Profile not set:', profileData);
        }
    };

    const mixpanelContextValue: MixpanelContextType = {
        startTimeMeasurement,
        trackEvent,
        identifyUser,
        setUserProfile,
    };

    return (
        <MixpanelContext.Provider value={mixpanelContextValue}>
            {children}
        </MixpanelContext.Provider>
    );
};

// Custom Hook to Use Mixpanel Context
export const useMixpanel = (): NonNullable<MixpanelContextType> => {
    const context = useContext(MixpanelContext);

    if (!context) {
        throw new Error('useMixpanel must be used within a MixpanelProvider');
    }

    return context;
};






