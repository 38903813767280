import React, { createContext, useContext, useState } from 'react';

interface ErrorOptions {
  errorWithInstructions?: boolean;
}

interface ErrorContextProps {
  error: string | null;
  errorOptions: ErrorOptions;
  setError: (message: string | null, options?: ErrorOptions) => void;
}

const ErrorContext = createContext<ErrorContextProps | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [error, setErrorState] = useState<string | null>(null);
  const [errorOptions, setErrorOptions] = useState<ErrorOptions>({});

  const setError = (message: string | null = null, options: ErrorOptions = {}) => {
    if (message === null && !options.errorWithInstructions) {
      setErrorState(null);
      setErrorOptions({});
      return;
    }

    if (!message && !options.errorWithInstructions) {
      throw new Error('Either an error message or error options must be provided.');
    }
    setErrorState(message);
    setErrorOptions(options);
  };

  return (
    <ErrorContext.Provider value={{ error, errorOptions, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useErrorContext must be used within an ErrorProvider');
  }
  return context;
};