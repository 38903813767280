import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import DocxReader from "./DocxReader";
import PrepareArticle from "./PrepareArticle";
import JournalOrBibliographyDropDown from "./JournalOrBibliographyDropDown";
import PublishersDropDown from "./PublishersDropDown";
import { useArticleContext } from "../contexts/ArticleContext";
import {useMixpanel} from "../contexts/MixpanelContext";
import Instruction from "./Instruction";
import ArticleDetails from "./ArticleDetails";
import GlobalError from "./GlobalError";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const ArticleFormatter = () => {
    const {
        publisher,
        journal,
        bibliography,
        article
    } = useArticleContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <Box>
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom align="center">
                    Format your article for any Journal in seconds
                </Typography>
                <Typography 
                    variant="h6" 
                    gutterBottom 
                    align="left" 
                    marginTop={2}
                    sx={{ 
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Box 
                        component="span" 
                        sx={{ 
                        display: 'inline-flex',
                        bgcolor: '#5BD7D6',
                        color: 'white',
                        borderRadius: '15%',
                        padding: '4px',
                        marginRight: 2,
                        alignItems: 'center'
                        }}
                    >
                        <TrendingFlatIcon />
                    </Box>
                    First, prepare your Word document using our&nbsp;
                    <a
                        href="#"
                        onClick={handleOpenModal}
                        style={{
                            color: '#1976d2',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            marginLeft: '4px'
                        }}
                    >
                    instructions
                    </a>
                </Typography>
                <Instruction
                    open={isModalOpen}
                    onClose={handleCloseModal}
                />
                <Typography 
                    variant="h6" 
                    gutterBottom 
                    align="left" 
                    marginTop={2}
                    marginBottom={2}
                    sx={{ 
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Box 
                        component="span" 
                        sx={{ 
                        display: 'inline-flex',
                        bgcolor: '#5BD7D6',
                        color: 'white',
                        borderRadius: '15%',
                        padding: '4px',
                        marginRight: 2,
                        alignItems: 'center'
                        }}
                    >
                        <TrendingFlatIcon />
                    </Box>
                    Next, specify where you want to publish:
                </Typography>
                <PublishersDropDown />
                <Box sx={{ mt: 2 }}>
                    <JournalOrBibliographyDropDown />
                </Box>
                <Typography 
                    variant="h6" 
                    gutterBottom 
                    align="left" 
                    marginTop={4}
                    sx={{ 
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Box 
                        component="span" 
                        sx={{ 
                        display: 'inline-flex',
                        bgcolor: '#5BD7D6',
                        color: 'white',
                        borderRadius: '15%',
                        padding: '4px',
                        marginRight: 2,
                        alignItems: 'center'
                        }}
                    >
                        <TrendingFlatIcon />
                    </Box>
                    Finally, upload your article in *.docx file and Format Your Article
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <DocxReader />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <PrepareArticle />
                    <GlobalError />
                    <ArticleDetails />
                </Box>
            </Container>
        </Box>
    );
};

export default ArticleFormatter;