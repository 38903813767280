import React, { useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useErrorContext } from '../contexts/ErrorContext';
import Instruction from './Instruction';

const GlobalError = () => {
  const { error, errorOptions, setError } = useErrorContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!error && !errorOptions.errorWithInstructions) return null;

  return (
    <>
      <Alert severity="error" sx={{ width: '100%', mt: 2 }} onClose={() => setError(null)}>
        <AlertTitle>Error</AlertTitle>
        {errorOptions.errorWithInstructions ? (
          <>
            Your file requires adjustments, please check the below warnings and use{' '}
            <a
              href="#"
              onClick={() => setIsModalOpen(true)}
              style={{
                color: '#1976d2',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              instructions
            </a>
            {' '}to prepare your document.
          </>
        ) : (
          error
        )}
      </Alert>
      {errorOptions.errorWithInstructions && (
        <Instruction open={isModalOpen} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default GlobalError;